
























































import {Component, Prop, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {DocumentsActionTypes} from "@store/documents/Types";
import {IStatusDocument} from "@store/documents/Interface";
import {UsersActions} from "@store/users/types";
import {ProjectUser} from "@store/users/interfaces";
import {IUser} from "@store/user/Interface";

const NSDocuments = namespace('storeDocuments');
const NSUsers = namespace('usersModule');
const NSUser = namespace('storeUser');

@Component({
  name: 'ProjectEditStatusesForm',
})
export default class ProjectCardComponent extends Vue {

  @NSDocuments.Action(DocumentsActionTypes.A_GET_DOCUMENTS_STATUS_FOR_PROJECT)
  private getDocumentStatusesForProject!: (project_id: number) => Promise<IStatusDocument[]>

  @NSUsers.Action(UsersActions.A_GET_USERS_FOR_PROJECT)
  private getUsersForProject!: (project_id: number) => Promise<ProjectUser[]>

  @NSUsers.Action(UsersActions.A_CHANGE_ACCESS_USER_STATUSES)
  private changeAccessUserStatuses!: ({project_id, user_id, status_ids}) => Promise<void>

  @NSUser.State('user') private user!: IUser;

  @Prop(Number)
  private projectId!: number
  private loading: boolean = false
  protected rules = {
    user: [{required: true, message: 'Обязательное поле', trigger: 'blur'}],
    statuses: [{required: true, message: 'Обязательное поле', trigger: 'blur'}],
  }



  protected users: ProjectUser[] = []
  protected statuses: IStatusDocument[] = []

  private formWorkStatuses: { user: ProjectUser | null, statuses: number[] } = {
    user: null,
    statuses: [],
  }

  async mounted() {
    this.loading = true

    this.statuses = (await this.getDocumentStatusesForProject(this.projectId))
      .filter((status: IStatusDocument) => status.id)
    this.users = (await this.getUsersForProject(this.projectId)).filter((user: ProjectUser) => {
      return user.id !== this.user.id
    })

    this.loading = false
  }

  private saveModal() {
    if (this.formWorkStatuses.user) {
      this.changeAccessUserStatuses({
        project_id: this.projectId,
        user_id: this.formWorkStatuses.user.id,
        status_ids: this.formWorkStatuses.statuses,
      })
        .then(() => {
          this.$notify({
            title: 'Выполнено',
            message: 'Обновление успешно выполнено',
            type: 'success',
          })
          this.closeModal()
        })
        .catch(error => {
          this.$notify.error({
            title: 'Ошибка',
            message: error?.response?.data?.message || 'Обновление не удалось',
          })
        })
    }
  }

  private closeModal() {
    this.$emit('close')
  }

  private changeUser(user: ProjectUser) {
    this.formWorkStatuses.statuses = user.acceptedStatuses.map(((status: IStatusDocument) => status.id))
  }
}
